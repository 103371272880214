/* Add transitions for smoother scrolling between sections */
body, html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Hide horizontal scroll */
}



html {
  scroll-behavior: smooth;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  /* min-height: 100vh; */
  min-height: auto;
  background-image: linear-gradient(135deg, #6200ee 0%, #8c43ff 100%);
  color: #fff;
  overflow: hidden;
  z-index: 1;
  padding-top: 10rem;
  /* Add padding to move content below the top banner */
  background-image: linear-gradient(
    135deg,
    rgba(98, 0, 238, 0.85),
    rgba(140, 67, 255, 0.85)
  );
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

}

.header-shape:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  background: inherit;
  border-radius: 0 0 100% 100% / 0 0 10% 10%;
  z-index: -1;
}

/*.App-header:before {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 0;
    width: 100%;
    height: 150px;
    background: inherit;
    border-radius: 0 0 100% 100% / 0 0 100% 100%;
    z-index: -1;
}*/

/*.App-header:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 200px;
    background: inherit;
    border-radius: 0 0 100% 100% / 0 0 10% 10%;
    z-index: -1;
}
*/
.top-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: rgba(33, 37, 41, 0.85);
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  gap: 2rem;
  backdrop-filter: blur(5px);
}

.full-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  /*    background-image: linear-gradient(145deg, #fff, #f3f3f3); */
  background-image: linear-gradient(
    145deg,
    #fff,
    #e0e0e0
  ); /* Increased contrast */
  /* THIS BACKGROUND-IMAGE CONTROLS THE BACKGROUND COLORS */
  /* background-image: linear-gradient(145deg, #f8f9fa, #f3f3f3);*/
}

footer {
  background-color: #212529;
  color: #f8f9fa;
  padding: 1rem;
  display: flex;
  justify-content: center;
  gap: 1rem;
}

footer a {
  color: #f8f9fa;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}



/* Add these styles for the call-to-action button */
.cta-button {
  background-color: #fff;
  color: #6200ee;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.cta-button:hover {
  background-color: #e0e0e0;
  color: #8c43ff;
}

/* Add these styles for the app store icons */
.download-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: transparent;
  border: 2px solid #fff;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  color: #fff;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition: background-color 0.3s, color 0.3s;
}

.download-button:hover {
  background-color: #fff;
  color: #6200ee;
}

.download-button > * {
  vertical-align: middle;
}

.FaGooglePlay {
  color: #3cba54;
}

.FaApple {
  color: #000;
}

footer {
  background-color: #212529;
  color: #f8f9fa;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.footer-column {
  flex: 1;
}

.footer-column h4 {
  margin-bottom: 1rem;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 0.5rem;
}

footer a {
  color: #f8f9fa;
  text-decoration: none;
}

footer a:hover {
  text-decoration: underline;
}

.footer-bottom {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

/* Make the footer responsive */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: column;
    gap: 1rem;
  }

  .footer-column {
    text-align: center;
  }

  .footer-bottom {
    flex-direction: column;
    gap: 1rem;
  }
}




.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icons a {
  color: inherit;
  text-decoration: none;
}

.social-icons svg {
  width: 24px;
  height: 24px;
  fill: currentColor;
  transition: fill 0.3s;
}

.social-icons a:hover svg {
  fill: #fff;
}

/* Add these styles for better accessibility */
.App-header h1 {
  font-size: 2.5rem;
  margin: 0;
}

.App-header p {
  font-size: 1.5rem;
  margin: 0;
}

.App-header h1 {
  font-size: 2.5rem;
}

.App-header p {
  font-size: 1.5rem;
}

.App-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* other existing styles */
}


.logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: #fff;
}

/*.logo svg {
    width: 2px;
    height: 32px;
}
*/

/*.logo-main {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-decoration: none;
    color: #fff;
}
*/

.logo-main {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  /*  font-size: 1.5rem;*/
  /*  font-weight: bold;*/
  margin: 0;
}

.logo-img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.section-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
  }
}

.App-download {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
  /*  gives more space between the get started button and others*/
}

.App-download .download-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.App-download svg {
  width: 250px;
  /*    STORE ICON TO INCREASE THE SIZE OF THE ICONS*/
  height: auto;
}

/* Add these styles for the download section */
.download-section {
  background-image: linear-gradient(
    135deg,
    rgba(98, 0, 238, 0.1),
    rgba(140, 67, 255, 0.1)
  );
  padding: 2rem 0;
}

.download-heading {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.store-icon {
  width: 450px; /* Increase the size of icons */
  height: auto;
  cursor: pointer;
  transition: transform 0.3s;
}

.store-icon:hover {
  transform: scale(1.05); /* Add a hover effect */
}

.header-section-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1500px;
  /* Adjust as needed */
  margin: 0 auto;
}

.left-column,
.right-column {
  flex: 1;
}

@media (max-width: 768px) {
  .header-section-content {
    flex-direction: column;
  }
}

.left-column {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-direction: column;
  height: 100%;
  /* Set height to 100% to allow the content to be pushed to the bottom */
  text-align: center;
  /* Add this line to horizontally center the text */
}

.left-column-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /*    margin-bottom: 2rem;*/
  /* Add margin to create space */
  text-align: left;
  /* Add to left-align text */
  /* width: 100%; */
  max-width: 600px;
  /* Adjust as needed */
  padding-left: 1rem;
  /* Add padding to left side of content */
}

@media (max-width: 768px) {
  .left-column-content {
    margin-bottom: 1rem;
    /* padding: 10px; */
    /* Remove padding on small screens */
  }
}

.responsive-image-translationScreen {
  width: 100%;
  /* Set the max-width if needed */
  max-width: 1000px !important;
}

.responsive-image-worldImage {
  width: 100%;
  /* Set the max-width if needed */
  max-width: 800px !important;
}

.responsive-image-airobotImage {
  width: 100%;
  /* Set the max-width if needed */
  max-width: 600px;
}
.responsive-image-connectImage {
  width: 100%;
  /* Set the max-width if needed */
  max-width: 600px;
}




.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
}





.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* width: 100%; */
  max-width: 600px;
  padding: 1rem;
}

.custom-bullet {
  list-style: none;
  counter-reset: list-counter;
  padding-left: 1.5rem;
}

.custom-bullet li {
  position: relative;
  counter-increment: list-counter;
  margin-bottom: 1rem;
}

.custom-bullet li::before {
  content: counter(list-counter);
  position: absolute;
  left: -1.5rem;
  top: 0;
  font-weight: bold;
  color: #6200ee;
}

/*@media (max-width: 768px) {
  .section-content {
    flex-direction: column;
  }
}
*/

/* Add these styles for responsive design */
@media (max-width: 768px) {

  .custom-bullet {
    padding-left:0.5rem;
  }
  
  .top-banner {
    flex-direction: column;
    /* padding: 1rem 0; */
  }

  .App-download .download-icons {
    flex-direction: column;
  }
  /* .logo-main {
    margin-top: 1rem;
  }*/
  
  .nav-links {
    flex-direction: column;
  }

  .nav-links a {
    margin: 0.5rem 0;
  }
  .nav-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* nav {
    margin-right: 0;
    flex-wrap: wrap;
    gap: 0rem;
  } */

  .full-page {
    padding: 1rem;
  }

  .store-icon {
    width: 100%; /* updated */
    max-width: 450px;
  }
}

/* Add these styles for even smaller screens */
@media (max-width: 480px) {
  .nav-links {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; /* Force all elements to be on the same row */
    gap: 0.5rem;
  }
}

@media (max-width: 360px) {
  .nav-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Make the elements wrap when there's not enough space */
    gap: 0.5rem;
  }
}


nav {
    display: flex;
    /* gap: 1rem; */
    justify-content: center;
  }

.nav-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    /* Adjust the width as needed */
  }
  
  nav > * {
    /* margin-left: 2rem; */
    color: #ddd; /* make normal links slightly dim */
    font-weight: 600;
  }
  
  .nav-links a {
    color: #ddd; /* make normal links slightly dim */
    margin: 0 10px; /* Adjust this value */
  }
  
  .nav-links a.active {
    color: #fff; /* make active link brighter */
    font-weight: 700; /* make active link bolder */
  }



  .youtube-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

/* Add these styles for responsive design */
@media (max-width: 768px) {
  .youtube-section {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

}



.iframe-wrapper {
    position: relative;
    width: 80%; /* Change this value as needed */
    padding-top: 45%; /* 9:16 (16/9 * 80) Aspect Ratio, adjust according to the width */
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
}

.iframe-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}






/* .nav-links {
  display: flex;
  justify-content: center;
  list-style-type: none;
} */

